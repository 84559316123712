import http from "../HTTPService";

import { useQuery } from "@tanstack/react-query";

export interface Settings {
  admin: Admin;
  admins: Admin[];

  servers: Server[];
  organisations: Organisation[];

  roles?: Role[];
}

export interface Organisation {
  id: string;
  name: string;
}

export interface AdminRole {
  id: string;
  steam_id: string;
  role_id: string;
  role_name: string;
}

export interface Role {
  id: string;
  name: string;
  description: string;
}

export interface Admin {
  id: string;
  steam_id: string;
  consumer_id: number;
  name: string;
  discord_id: string;
  migration_id?: string;
  roles: AdminRole[];

  is: (role: string) => boolean;
}

export interface Server {
  id: string;
  name: string;
  region: string;
  stats_name: string;
  organisation: string;
  description: string;
  sort_order: number;
  group_limited: boolean;
}

export default function useSettings() {
  const fetchSettings = async () => {
    const { data } = await http.get("/api/v3/settings");

    const settings = data?.data as Settings;

    settings.admin.is = (role: string | number) => {
      const matchedId = settings.roles?.find((r) => r.name === role)?.id;

      const hasRole = settings?.admin?.roles?.some(
        (x) => x.role_id === matchedId || x.role_id === role
      );

      return hasRole;
    };

    return settings;
  };

  return useQuery<Settings>({
    queryKey: ["settings"],
    queryFn: fetchSettings,
    refetchInterval: -1,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retry: (count, error) => {
      // @ts-ignore
      if (error?.response?.status === 401) {
        return false;
      }

      return count < 3;
    },
  });
}
