import http from "../HTTPService";
import { useInfiniteQuery, } from "@tanstack/react-query";
import Ticket from "./types/Ticket";

export function useStaffApplications(steam_id?: string) {
  const fetchStaffApplications = async (before: string) => {
    const { data } = await http.get("/api/v3/staff-applications", {
      params: { steam_id, before },
    });

    return data.data ?? [];
  };

  return useInfiniteQuery<Ticket[]>({
    queryKey: ["staff-applications", steam_id],
    queryFn: (data) => fetchStaffApplications(data.pageParam),
    refetchInterval: 5000,
    getNextPageParam: (lastPage) => {
      const last = lastPage?.[lastPage?.length - 1];
      return last?.id;
    },
  });
}
