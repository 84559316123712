import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useStaffApplications } from "../../../Services/HTTP/useStaffApplication";
import { StaffApplication } from "./../../../Services/HTTP/types/StaffApplication";
import Spinner from "../../Spinner";
import ReactTimeago from "react-timeago";
import Modal from "../../Modal";
import { Button } from "antd";
import { Link } from "react-router-dom";

export default function StaffApplications() {
  const { ref, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
  });

  const {
    data: pages,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useStaffApplications();

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const staffApplications = React.useMemo(
    () => pages?.pages.flatMap((page) => page),
    [pages]
  );

  const loading = React.useMemo(
    () => isLoading || isFetchingNextPage,
    [isLoading, isFetchingNextPage]
  );

  return (
    <Container fluid>
      <Row className="pt-4">
        <Col md="12">
          <h2>Staff Applications</h2>

          <div className="tw-space-y-2 tw-text-xs">
            {!loading && staffApplications?.length === 0 && (
              <p className="tw-mb-0">Nothing to show...</p>
            )}

            {staffApplications?.map((staffApplication) => (
              <StaffApplicationRow
                key={staffApplication.id}
                staffApplication={staffApplication}
              />
            ))}
          </div>

          {loading && <Spinner className="my-3" />}

          <div ref={ref}>&nbsp;</div>
        </Col>
      </Row>
    </Container>
  );
}

function StaffApplicationRow({ staffApplication }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Modal
        visible={open}
        title={
          <p className="tw-capitalize">
            {staffApplication.username +
              " - " +
              staffApplication.type.replace("_", " ")}
          </p>
        }
        description={
          <div>
            {staffApplication.answers.map(({ question, answer }, index) => (
                <div key={index}>
                    <p className="tw-font-bold">{question}:</p>

                    <p>{answer}</p>
                </div>
            ))}

            <div className="tw-flex tw-items-center tw-gap-3">
              <Link to={`/player/${staffApplication.steam_id}`} target="_blank">
                <Button onClick={() => setOpen(false)} type="primary">
                  Rust Legends Profile
                </Button>
              </Link>

              <Button onClick={() => setOpen(false)} danger type="primary">
                Close
              </Button>
            </div>
          </div>
        }
      />

      <div
        onClick={() => setOpen(!open)}
        className="tw-bg-neutral-900 tw-w-full tw-p-3 tw-rounded-sm hover:tw-bg-neutral-800 tw-transition-all tw-border-[1px] tw-border-neutral-800 tw-cursor-pointer"
      >
        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-3 tw-items-start sm:tw-items-center md:tw-space-y-0 tw-space-y-1 tw-justify-between">
          <div className="tw-flex-1">
            <p className="tw-mb-0">{staffApplication.username}</p>
          </div>

          <div className="tw-flex-1">
            <p className="tw-mb-0">{staffApplication.type}</p>
          </div>

          <div className="tw-flex-1">
            <p className="tw-mb-0">{staffApplication.message?.slice(0, 50) ?? ""}</p>
          </div>

          <div className="tw-flex-1">
            <p className="tw-mb-0">
              Created&nbsp;
              <ReactTimeago date={staffApplication.created_at} />
            </p>
          </div>

          <div className="tw-flex-1">
            <p className="tw-mb-0">
              Updated&nbsp;
              <ReactTimeago date={staffApplication.updated_at} />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
